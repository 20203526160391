<template>
    <div>
        <div @click="$router.push('/group-view')" class="flex text-[#FFF] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>

        <div>
            <div class="text-[#FFF] text-center font-medium">
                ПРОФІЛЬ НОВОГО ГРАВЦЯ
            </div>
        </div>

        <div>
            <div class="student-data-table mt-4 mx-4 border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-6 py-1">
                <div class="flex text-[#FFF] justify-between border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold flex text-lg w-[65%]">
                        <div class="text-white font-bold mr-[3px]">+380</div>
                        <input v-model="newStudent.phone" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="0000000000">
                    </div>
                    <div class="font-light text-sm w-max">номер/логін</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="newStudent.password" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="qwerty"></div>
                    <div class="font-light text-sm">пароль</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="newStudent.name" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="Іван"></div>
                    <div class="font-light text-sm">ім'я</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="newStudent.patronymic" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="Іванович"></div>
                    <div class="font-light text-sm">по батькові</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="newStudent.surname" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="Іванов"></div>
                    <div class="font-light text-sm">прізвище</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="birthdayDate" type="date" class="bg-[#02130E] text-[#FFF] w-full outline-none border-none"></div>
                    <div class="font-light text-sm">ДН</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="newStudent.patrons[0].name" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="Іван Іванов"></div>
                    <div class="font-light text-sm">відпов. особа</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[65%]"><input v-model="newStudent.patrons[0].phone_number" type="text" class="bg-transparent text-[#FFF] w-full outline-none border-none placeholder:text-[#FFF] placeholder:opacity-40" placeholder="3800000000000"></div>
                    <div class="font-light text-sm">номер в.о.</div>
                </div>
            </div>
        </div>

        <button @click="addNewStudent" class="bg-[#5AC47D] rounded-[9px] text-[#02130E] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between my-5 mx-auto">
            <div class="w-full">Додати гравця</div>
        </button>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import { addNewStudentRequest, addGroupToStudents } from '../services/apiRequests'

    export default {
        name: 'AddNewStudent',

        setup() {
            const birthdayDate = ref('2023-01-12')
            const store = useMainStore()
            const router = useRouter()
            const newStudent = ref({
                    name: '',
                    surname: '',
                    patronymic: '',
                    name_eng: '',
                    surname_eng: '',
                    passport: '',
                    passport_end_date: '',
                    id_number: '',
                    birthday: '',
                    height: 0,
                    weight: 0,
                    foot_size: 0,
                    leading_foot: '',
                    breast_volume: 0,
                    start_training: '',
                    group_id: '',
                    group_name: '',
                    amplua: '',
                    player_number: 0,
                    password: '',
                    login: '',
                    email: '',
                    phone: '',
                    nationality: 'UKR',
                    status: ['active'],
                    patrons: [
                        {
                            name: '',
                            phone_number: ''
                        }
                    ],
                    date_active_to: new Date().toISOString(),
                    last_tariff_id: "",
                    tariff_role_id: "",
                    prepaid_balance: 0
                })

            const addNewStudent = async () => {
                const birthdayDateFormatted = new Date(birthdayDate.value).toISOString()
                newStudent.value.birthday = birthdayDateFormatted
                newStudent.value.login = '380' + newStudent.value.phone
                newStudent.value.passport_end_date = new Date().toISOString() 
                newStudent.value.start_training = new Date().toISOString()
                const res = await addNewStudentRequest(newStudent.value)
                const student_id = res.data.student_id
                await addGroupToStudents([student_id], store.currentGroup.group_id, store.currentGroup.name)
                router.push('/group-view')
            }

            return {
                newStudent,
                birthdayDate,
                addNewStudent
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>