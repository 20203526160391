<template>
    <div>
        <div @click="$router.push('/group-view')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div class="flex justify-center mt-[10px]">
            <input v-model="studentQuery" type="text" class="bg-transparent border-[2px] border-[#FFF] px-3 py-2 w-[92%] rounded-[9px] placeholder:text-[#FFF] placeholder:opacity-60 outline-none select-none text-[#FFF]" placeholder="Ім'я та прізвище учня">
        </div>
        <div class="relative">
            <div v-if="visibleStudents.length > 0" class="flex flex-col items-center absolute w-full h-[300px] overflow-y-auto">
                <div v-for="student in visibleStudents" @click="addStudent(student)" class="text-[#FFF] bg-[#323233] px-3 py-3 border-b-[1px] border-[#02130E] w-[92%]">
                    <div class="text-[18px]">{{ student.name + ' ' + student.surname }}</div>
                </div>
            </div>
        </div>
        <div class="mt-[20px] px-[20px] h-[70vh] overflow-y-auto">
            <div v-if="newStudents.length > 0" v-for="student in newStudents" class="flex justify-between items-center mb-[10px]">
                <div class="flex w-[70%]">
                    <div class="mr-[10px]">
                        <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#5AC47D] border-[2px]">
                            <img v-if="student.photo?.length > 0" :src="apiURL + 'static/' + student.photo">
                            <div v-else class="w-full h-full bg-[#5AC47D] bg-opacity-40 text-[#5AC47D] text-[16px] font-semibold flex justify-center items-center">
                                {{ student.name[0] + student.surname[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="w-[75%]">
                        <div class="text-[#FFF] font-semibold truncate">
                            {{ student.name }} {{ student.surname }}
                        </div>
                        <div>
                            <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-[#02130E] w-full absolute bottom-0">
            <button @click="addStudentsToGroup" class="bg-[#5AC47D] rounded-[9px] text-[#02130E] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between my-5 mx-auto">
                <div class="w-full">Додати гравців</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, watch, onMounted, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { getAllStudentsRequest, addGroupToStudents } from '../services/apiRequests'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'AddStudentToGroup',

        setup() {
            const router = useRouter()
            const store = useMainStore()

            const studentQuery = ref('')
            const visibleStudents = ref([])
            const newStudents = ref([])

            const addStudent = (student) => {
                newStudents.value.push(student)
                studentQuery.value = ''
                visibleStudents.value = []
            }

            const addStudentsToGroup = async () => {
                const studentsIds = newStudents.value.map(student => student.student_id)
                const res = await addGroupToStudents(studentsIds, store.currentGroup.group_id, store.currentGroup.name)
                console.log(res)
                router.push('/group-view')
            }

            watch(studentQuery, async (value) => {
                if (studentQuery.value.length > 3) {
                    const res = await getAllStudentsRequest(studentQuery.value)
                    console.log(res)
                    visibleStudents.value = res.data
                } else {
                    visibleStudents.value = []
                }
            })

            return {
                studentQuery,
                visibleStudents,
                addStudent,
                newStudents,
                addStudentsToGroup
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>