<template>
    <div class="pb-[40px]">
        <div @click="$router.push('/')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div v-if="!loading">
            <button @click="$router.push('/add-new-student')" class="bg-[#5AC47D] rounded-[9px] text-[#02130E] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between my-5 mx-auto">
                <div class="w-full">Новий гравець</div>
            </button>
            <button @click="$router.push('/add-student-to-group')" class="bg-[#D8E2DC] rounded-[9px] text-[#02130E] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between my-5 mx-auto">
                <div class="w-full">Додати гравця до групи</div>
            </button>
            <div class="flex items-center">
                <div class="h-px w-[30%] bg-[#FFF]"></div>
                <div class="inline-block w-[40%] text-center text-sm text-[#FFF] font-bold">
                    ГРУПА {{ store.currentGroup.name }}
                </div>
                <div class="h-px w-[30%] bg-[#FFF]"></div>
            </div>
    
            <div class="mx-[20px] mt-[10px] pb-[10px] border-b-[1px] border-[#FFF]">
                <div v-if="store.allStudentsInGroup.length > 0" v-for="student in store.allStudentsInGroup" @click="selectStudent(student)" class="flex justify-between items-center mb-[10px]">
                    <div class="flex w-[70%]">
                        <div class="mr-[10px]">
                            <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#5AC47D] border-[2px]">
                                <img v-if="student.photo?.length > 0" :src="apiURL + 'static/' + student.photo">
                                <div v-else class="w-full h-full bg-[#5AC47D] bg-opacity-40 text-[#5AC47D] text-[16px] font-semibold flex justify-center items-center">
                                    {{ student.name[0] + student.surname[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="w-[75%]">
                            <div class="text-[#FFF] font-semibold truncate">
                                {{ student.name }} {{ student.surname }}
                            </div>
                            <div>
                                <div class="truncate text-[10px] text-gray-400">{{ '#' + student.player_number + ' | ' + student.amplua + ' | ' + student.age + 'p. | ' + student.nationality }}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div v-if="compareDates(student.date_active_to)" class="bg-[#CA3F79] mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                            Тариф
                        </div> 
                        <div v-else-if="student.status.includes('active')" class="bg-[#5AC47D] mx-1 h-[18px] pt-[2px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                            Активний
                        </div>  
                    </div>
                </div>
                <div v-else class="text-center text-[#FFF] my-[100px]">
                    В групі нема учнів
                </div>
            </div>
    
            <div class="text-center text-[#FFF] mt-[15px]">
                ВСЬОГО АКТИВНИХ - <span class="font-bold">{{ store.allStudentsInGroup.length }}</span>
            </div>
        </div>
        <div v-else>
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { getStudentBalanceDataRequest } from '../services/apiRequests'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    
    export default {
        name: 'GroupView',

        setup() {
            const store = useMainStore()
            const apiURL = process.env.VUE_APP_API_URL
            const loading = ref(true)
            const router = useRouter()

            const currentDate = new Date()

            const compareDates = (date) => {
                const dateObj = new Date(date)
                
                if (dateObj < currentDate) {
                    return true
                } else {
                    return false
                }
            }

            onMounted(async () => {
                await store.getStudentsInGroupForView()

                // for (let i = 0; i < store.allStudentsInGroup.length; i++) {
                //     const student = store.allStudentsInGroup[i]
                //     const studentBalanceData = await getStudentBalanceDataRequest(student.balance_id)
                //     store.allStudentsInGroup[i].balance = studentBalanceData.data.balance_state
                // }

                loading.value = false
            })

            const selectStudent = (student) => {
                store.currentStudent = student
                router.push('/current-student-view')
            }

            return {
                store,
                apiURL,
                loading,
                selectStudent,
                compareDates,
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>