import axios from 'axios'

export async function getTokens(login, password) {
    const session_url = process.env.VUE_APP_API_URL + 'auth/trainer_auth'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            username: login,
            password: password
        }
    }

    const response = await axios(config)
    console.log('response', response)
    if (response.data.status != 'error') {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        return true
    } else {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        return false
    }
}

async function rewriteTokensRequest() {
    const session_url = process.env.VUE_APP_API_URL + 'auth/trainer_refresh'
    
    const config = {
        method: 'post',
        url: session_url,
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem('refresh_token') }
    }

    let response = {}
    try {
        response = await axios(config)
    } catch (error) {
        response.status = 422
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
    }


    if (response.status != 200) {
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('refresh_token')
        // window.location.reload();
    } else if (response.status == 200) {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
    }
}

async function sendApiRequest(axiosConfig) {
    axiosConfig.headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token') 
    }
    let response = {}
    try {
        response = await axios(axiosConfig)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        await rewriteTokensRequest()
        axiosConfig.headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token') 
        }
        try {
            response = await axios(axiosConfig)
        } catch (error) {
            // localStorage.removeItem('access_token')
            // localStorage.removeItem('refresh_token')
            // window.location.reload();
        }
        
        return response
    } else if (response.status == 200) {
        return response
    }
}

export const getTrainerDataRequest = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_by_credentials'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequest(config)
}

export const getStudentBalanceDataRequest = async (balance_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'balance/get_balance_state'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerGroupsRequest = async (trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_groups_by_trainer_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getPlanedTrainingsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId,
            current_date: new Date().toISOString()
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsByGroupIdRequest = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const processPresentStudents = async (students, training_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_completed_training_to_student_list'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_id: training_id,
            students: students
        }
    }

    return await sendApiRequest(config)
}

export const processMissedStudents = async (students, training_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_missed_trainings_by_student_list'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_id: training_id,
            students: students
        }
    }

    return await sendApiRequest(config)
}

export const updateTrainingStatus = async (training_id, status) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_status'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_id: training_id,
            status: status
        }
    }

    return await sendApiRequest(config)
}

export const getStudentVirtualBalanceRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'balance/get_balance_state'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const addVirtualIncomeRequest = async (balanceId, amount, label) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_virtual_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label
    }
    formData.append('virtual_income', JSON.stringify(txConfig))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const addRealIncomeRequest = async (balanceId, amount, label, virtualTxId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label,
        embed_virtual_income: virtualTxId
    }

    formData.append('real_income', JSON.stringify(txConfig))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getRealIncomesByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_real_incomes_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getAllStudentsRequest = async (query = null) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_all_students'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            count: 100,
            skip: 0,
            query: query,
            balance_sort: "desc",
            status: [
              "active"
            ],
            age_from: 0,
            age_to: 100
        }
    }

    return await sendApiRequest(config)
}

export const getCompletedTrainingsByGroup = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_completed_trainings_by_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichAttendedTraining = async (trainingDate, groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_attended_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichMissedTraining = async (trainingDate, groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_missed_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const deleteCompletedTrainingFromStudent = async (studentId, trainingFlag, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_completed_training_from_student'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            student_id: studentId,
            training_flag: trainingFlag,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const addCompletedTrainingToStudent = async (studentId, trainingFlag, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_completed_training_to_student'
    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
            training_flag: trainingFlag,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const changeMissedToAttendedTraining = async (studentId, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/change_missed_to_attended_training'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const changeAttendedToMissedTraining = async (studentId, trainingDate) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/change_attended_to_missed_training'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
            training_date: trainingDate
        }
    }

    return await sendApiRequest(config)
}

export const getTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_training_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const deleteTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_training_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

// function formatDate(date) {
//     let month = '' + (date.getMonth() + 1),
//         day = '' + date.getDate(),
//         year = date.getFullYear()

//     if (month.length < 2) month = '0' + month
//     if (day.length < 2) day = '0' + day

//     return [year, month, day].join('-')
// }


export const updateTrainingById = async (trainingId, groupName, groupId, locationName, locationId, startTime, endTime, date, type, description, trainer_id, trainer_label, old_additional_files, new_additional_files) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_by_id'
    
    let formattedDate = null

    // if (typeof date === 'string') {
    //     formattedDate = (new Date(date + ' GMT')).toISOString()
    // } else {
    //     formattedDate = (new Date(formatDate(date) + ' GMT')).toISOString()
    // }

    // formattedDate = (new Date(date + ' GMT')).toISOString()

    formattedDate = new Date(date)
    
    const data = {
        group_id: groupId,
        group_label: groupName,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        location_id: locationId,
        location_label: locationName,
        type: type,
        description: description,
        trainer_id: trainer_id,
        trainer_label: trainer_label
    }

    const formData = new FormData()


    formData.append('training', JSON.stringify(data))

    old_additional_files.forEach((file) => {
        console.log('file', file)
        formData.append('old_additional_files', file)
    })

    new_additional_files.forEach((file) => {
        formData.append('new_additional_files', file)
    })

    console.log('old_additional_files -------- ', old_additional_files)

    // alert('old_additional_files.length ' + old_additional_files.length)

    const config = {
        method: 'put',
        url: session_url + '?training_id=' + trainingId,
        data: formData
    }
    return await sendApiRequest(config)
}

export const getPlanedTrainingsByDate = async (date) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_date'

    const days = new Date(date).getDate() + 1
    const month = new Date(date).getMonth()
    const year = new Date(date).getFullYear()


    const config = {
        method: 'get',
        url: session_url,
        params: {
            date: new Date(year, month, days, 0, 0, 0, 0).toISOString()
        }
    }

    return await sendApiRequest(config)
}

export const getAllGroups = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_groups'

    const config = {
      method: 'get',
      url: session_url,
    }
    
    return await sendApiRequest(config)
}

export const getAllLocations = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_all_locations'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequest(config)
}

export const addNewTraining = async (groupName, groupId, locationName, locationId, startTime, endTime, date, trainingType, description, trainer_id, trainer_label, additional_files) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_new_training'
    let formattedDate = null

    if (typeof date === 'string') {
        formattedDate = (new Date(date)).toISOString()
    } else {
        formattedDate = (new Date(formatDate(date))).toISOString()
    }

    const data = {
        group_id: groupId,
        group_label: groupName,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        location_id: locationId,
        location_label: locationName,
        type: trainingType,
        description: description,
        trainer_id: trainer_id,
        trainer_label: trainer_label
    }

    const formData = new FormData()

    formData.append('training', JSON.stringify(data))   

    additional_files.forEach((file) => {
        formData.append('additional_files', file)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }
    
    return await sendApiRequest(config)
}

export const addNewStudentRequest = async (new_student_obj) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/add_new_student'

    const formData = new FormData()
    console.log('new_student_obj ----- ', new_student_obj)
    formData.append('new_student', JSON.stringify(new_student_obj))

    const config = {
      method: 'post',
      url: session_url,
      data: formData
    }
    return await sendApiRequest(config)
}

export const addGroupToStudents = async (studentsIds, groupId, groupName) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_list_of_students_to_group'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            group_id: groupId,
            group_name: groupName
        },
        data: studentsIds
    }

    return await sendApiRequest(config)
}

export const updateStudent = async (student_obj) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student'

    const formData = new FormData()

    formData.append('updated_student', JSON.stringify(student_obj))

    const config = {
        method: 'put',
        url: session_url,
        data: formData,
        params: {
            student_id: student_obj.student_id
        }
    }

    return await sendApiRequest(config)
}

export const getTransactionsByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_transactions_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getAllIncomesByGroupInYear = async (year) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_groups_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerCompletedTrainingsByYear = async (year, trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainer_completed_trainings_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getGroupsAttendanceByYearAndMonth = async (year, month) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_groups_attendance_by_month_and_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequest(config)
}

export const broadcastMessageToGroup = async (groupId, message) => {
    const session_url = process.env.VUE_APP_API_URL + 'messages/broadcast_message_to_group_by_trainer'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId,
            message_text: message
        }
    }

    return await sendApiRequest(config)
}

export const getStudentAttendedTrainingsByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

// /messages/get_all_broadcasted_messages

export const getAllBroadcastedMessages = async (sender_id, count, skip) => {
    const session_url = process.env.VUE_APP_API_URL + 'messages/get_all_broadcasted_messages'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            sender_id: sender_id,
            count: count,
            skip: skip
        }
    }

    return await sendApiRequest(config)
}

export const getTrainingsByTrainerId = async (trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainings_by_trainer_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getAllTrainers = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_all_trainers'
    const config = {
      method: 'get',
      url: session_url
    }
    return await sendApiRequest(config)
}

export const getTariffsByRoleId = async (roleId) => {
    const session_url = process.env.VUE_APP_API_URL + 'tariffs/get_tariffs_by_role_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            role_id: roleId
        }
    }

    return await sendApiRequest(config)
}

export const addTariffIncome = async (amount, label, receiver_balance_id, student_id, student_name, new_prepaid_balance, prepaid_income, tariff_id, tariff_name, additional_files) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_income_tariff'

    const data = {
        transactions_purpose: "tariff_payment",
        amount: amount,
        label: label,
        receiver_balance_id: receiver_balance_id,
        student_id: student_id,
        student_name: student_name,
        new_prepaid_balance: new_prepaid_balance,
        prepaid_income: prepaid_income,
        tariff_id: tariff_id,
        tariff_name: tariff_name
    }

    const formData = new FormData()

    formData.append('real_income_tariff', JSON.stringify(data))

    additional_files.forEach((file) => {
        formData.append('additional_files', file)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getAssistantGroupsRequest = async (assistantId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_assistants_groups'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            assistant_id: assistantId
        }
    }

    return await sendApiRequest(config)
}