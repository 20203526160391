<template>
  <div v-if="loadingSpinnerFlag" class="bg-[#02130E] z-50 fixed w-full h-full">
    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
  </div>
  <router-view v-else/>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useMainStore } from './stores/mainStore'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'main-view',

    setup() {
        const store = useMainStore()
        const router = useRouter()
        const route = useRoute()

        const loadingSpinnerFlag = ref(true)

        const logout = () => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            router.push('/login')
            loadingSpinnerFlag.value = false
        }

        onMounted(async () => {
          if (localStorage.getItem('accessToken')) {
            router.push('/')
          }

          if (route.path == '/login') {
            loadingSpinnerFlag.value = false
            return
          }
          try {
            const resAuth = await store.getTrainerData()
            if (resAuth.status == 422) {
              console.log('error')
              logout()
            }
            await store.getStudentBalanceData()
            await store.getServiceBalanceData()
            await store.getTrainerGroups()
            await store.getAllTrainerPlanedTrainings()
            await store.getTrainerRealIncomes()
            loadingSpinnerFlag.value = false
          } catch (e) {
            console.log(e)
          }
        })

        return {
            logout,
            loadingSpinnerFlag
        }
    },
}

</script>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #02130E;
  }

  ::-webkit-scrollbar {
      width: 5px;      
      height: 2px;          /* ширина всей полосы прокрутки */
  }

  ::-webkit-scrollbar-track {
      background: transparent;        /* цвет зоны отслеживания */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;    /* цвет бегунка */
    border-radius: 20px;       /* округление бегунка */
    height: 2px;               /* высота горизонтальной прокрутки */
}
</style>