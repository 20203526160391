<template>
    <div class="bg-[#02130E] w-full h-full pt-2 pb-5 rounded-lg px-2 flex justify-between flex-col">
        <div class="flex justify-end">
            <img @click="$emit('close')" src="@/mobileApp/assets/cross_icon.svg" class="w-4 cursor-pointer z-50 fill-[#CA3F79]">
        </div>
        <div class="flex justify-center overflow-auto items-center h-[90%] bg-slate-400 mx-auto rounded-[10px] bg-opacity-30 w-[90%] mt-[-19px]">
            <img :src="imageDataURL" class="w-[100%] mx-auto">
        </div>
        <div class="flex justify-center mt-2">
            <button @click="deletePhoto" class="bg-[#CA3F79] mr-2 hover:bg-[#bf2c5f] text-white px-2 py-1 rounded-[10px]">Видалити</button>
            <button @click="downloadPhoto" class="bg-[#CA3F79] hover:bg-[#bf2c5f] text-white px-2 py-1 rounded-[10px]">Завантажити</button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import axios from 'axios'
    export default {
        name: 'image-viewer',
        props: {
            image: {
                type: [Object, String],
                default: () => ({})
            },
            photoType: {
                type: String,
                required: true
            }
        },
        setup(props, { emit }) {
            const imageDataURL = ref(null)

            const downloadPhoto = () => {
                const link = document.createElement('a')
                if (typeof props.image === 'string') {
                    link.href =  imageDataURL.value
                    link.download = props.image
                } else {
                    link.href = URL.createObjectURL(props.image)
                    link.download = props.image.name
                }
                link.click()
                URL.revokeObjectURL(link.href)
            }

            const deletePhoto = () => {
                emit('deletePhoto')
                emit('close')
            }

            onMounted(() => {
                if (typeof props.image === 'string') {
                    const url = process.env.VUE_APP_API_URL + 'static/' + props.image
                    axios.get(url, { responseType: 'blob' })
                        .then(response => {
                            imageDataURL.value = URL.createObjectURL(response.data)
                        }).catch(console.error)
                } else {
                    let fr = new FileReader()
                    fr.onload = () => {
                        imageDataURL.value = fr.result
                    }
                    fr.readAsDataURL(props.image)
                }
            })

            return {
                imageDataURL,
                downloadPhoto,
                deletePhoto
            }
        }
    }
</script>
