<template>
    <div class="text-[#CA3F79] font-semibold text-center">
      <p v-if="timerExpired">Час вийшов</p>
      <p v-else>
        {{ days }} дн. {{ hours }} год. {{ minutes }} хв. {{ seconds }} сек.
      </p>
    </div>
  </template>
  
  <script>
    import { ref, computed, watchEffect, onMounted } from 'vue'
  
    export default {
      name: 'CountdownTimer',
  
      props: {
        targetDate: {
          type: String,
          required: true
        },
        targetTime: {
            type: String,
            required: true
        }
      },
  
      setup(props) {
        const timerExpired = ref(false)
        const timeRemaining = ref({})
        const targetTime = new Date(`${props.targetDate} ${props.targetTime}`).getTime()
  
        const updateTimer = () => {
          const currentTime = new Date().getTime()
          const timeDiff = targetTime - currentTime
  
          if (timeDiff <= 0) {
            timerExpired.value = true
          } else {
            const seconds = Math.floor((timeDiff / 1000) % 60) >= 10 ? Math.floor((timeDiff / 1000) % 60) : '0' + Math.floor((timeDiff / 1000) % 60)
            const minutes = Math.floor((timeDiff / (1000 * 60)) % 60) >= 10 ? Math.floor((timeDiff / (1000 * 60)) % 60) : '0' + Math.floor((timeDiff / (1000 * 60)) % 60)
            const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24) >= 10 ? Math.floor((timeDiff / (1000 * 60 * 60)) % 24) : '0' + Math.floor((timeDiff / (1000 * 60 * 60)) % 24)
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  
            timeRemaining.value = { days, hours, minutes, seconds }
          }
        }

        onMounted(() => {
            updateTimer()
        })
  
        watchEffect(() => {
          const timer = setInterval(() => {
            updateTimer()
          }, 1000)
  
          return () => clearInterval(timer)
        })
  
        const days = computed(() => timeRemaining.value.days)
        const hours = computed(() => timeRemaining.value.hours)
        const minutes = computed(() => timeRemaining.value.minutes)
        const seconds = computed(() => timeRemaining.value.seconds)
  
        return {
          timerExpired,
          days,
          hours,
          minutes,
          seconds
        }
      }
    }
  </script>
  