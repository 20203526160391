import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    meta: {
      component: 'MainView'
    }
  },

  {
    path: '/select-group-for-training',
    name: 'groupSelection',
    component: MainLayout,
    meta: {
      component: 'AllTrainingsView'
    }
  },

  {
    path: '/student-roll-call',
    name: 'studentRollCall',
    component: MainLayout,
    meta: {
      component: 'StudentRollCallView'
    }
  },

  {
    path: '/wish-good-luck-on-training',
    name: 'wishGoodLuckOnTraining',
    component: MainLayout,
    meta: {
      component: 'GoodTrainingWishView'
    }
  },

  {
    path: '/receive-money',
    name: 'receiveMoney',
    component: MainLayout,
    meta: {
      component: 'ReceiveMoneyView'
    }
  },

  {
    path: '/success-transaction-view',
    name: 'successTransactionView',
    component: MainLayout,
    meta: {
      component: 'SuccessTransactionView'
    }
  },
  {
    path: '/completed-trainings',
    name: 'completedTrainings',
    component: MainLayout,
    meta: {
      component: 'CompletedTrainingsView'
    }
  },
  {
    path: '/completed-training-view',
    name: 'completedTrainingView',
    component: MainLayout,
    meta: {
      component: 'CompletedTrainingView'
    }
  },
  {
    path: '/add-new-training',
    name: 'addNewTraining',
    component: MainLayout,
    meta: {
      component: 'AddNewTraining'
    }
  },
  {
    path: '/group-view',
    name: 'groupView',
    component: MainLayout,
    meta: {
      component: 'GroupView'
    }
  },
  {
    path: '/current-student-view',
    name: 'currentStudentView',
    component: MainLayout,
    meta: {
      component: 'CurrentStudentView'
    }
  },
  {
    path: '/add-new-student',
    name: 'addNewStudent',
    component: MainLayout,
    meta: {
      component: 'AddNewStudent'
    }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: MainLayout,
    meta: {
      component: 'LoginView'
    }
  },
  {
    path: '/full-day-training',
    name: 'FullDayTrainingView',
    component: MainLayout,
    meta: {
      component: 'FullDayTrainingView'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: MainLayout,
    meta: {
      component: 'StatisticsView'
    }
  },
  {
    path: '/new-message',
    name: 'NewMessageView',
    component: MainLayout,
    meta: {
      component: 'NewMessageView'
    }
  },
  {
    path: '/all-messages',
    name: 'AllMessagesView',
    component: MainLayout,
    meta: {
      component: 'AllMessagesView'
    }
  },
  {
    path: '/current-message',
    name: 'CurrentMessageView',
    component: MainLayout,
    meta: {
      component: 'CurrentMessageView'
    }
  },
  {
    path: '/add-student-to-group',
    name: 'AddStudentToGroup',
    component: MainLayout,
    meta: {
      component: 'AddStudentToGroup'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
