<template>
    <div class="pb-[25px]">
        <div @click="$router.push('/group-view')" class="flex text-[#FFF] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>

        <div>
            <div class="text-[#FFF] text-center font-medium">
                ІНФОРМАЦІЯ ПРО ГРАВЦЯ
            </div>
        </div>

        <div class="mt-[10px]">
            <div class="student-data-table mt-4 mx-4 border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-6 py-1">
                <div class="flex text-[#FFF] justify-between border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.name">
                    </div>
                    <div class="font-light text-sm">Ім'я</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.surname">
                    </div>
                    <div class="font-light text-sm">Фамілія</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.patronymic">
                    </div>
                    <div class="font-light text-sm">По батькові</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none placeholder:text-[#DFF726] placeholder:opacity-40" v-model="store.currentStudent.name_eng" placeholder="Ivan">
                    </div>
                    <div class="font-light text-sm">name</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none placeholder:text-[#DFF726] placeholder:opacity-40" v-model="store.currentStudent.surname_eng" placeholder="Ivanov">
                    </div>
                    <div class="font-light text-sm">surname</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none placeholder:text-[#DFF726] placeholder:opacity-40" v-model="store.currentStudent.passport" placeholder="UA00000000">
                    </div>
                    <div class="font-light text-sm">паспорт</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none placeholder:text-[#DFF726] placeholder:opacity-40" v-model="store.currentStudent.id_number" placeholder="00000000000">
                    </div>
                    <div class="font-light text-sm">ID код</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg">{{ store.currentStudent.birthday.split('T')[0].split('-').reverse().join('.') }}</div>
                    <div class="font-light text-sm">день народження</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent w-[90%] truncate text-[#DFF726] outline-none placeholder:text-[#DFF726] placeholder:opacity-40" v-model="store.currentStudent.patrons[0].name" placeholder="Іванов Іван">
                    </div>
                    <div class="font-light text-sm">відпов. особа</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none placeholder:text-[#DFF726] placeholder:opacity-40" v-model="store.currentStudent.patrons[0].phone_number" placeholder="+380000000000">
                    </div>
                    <div class="font-light text-sm">номер в.о.</div>    
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.login">
                    </div>
                    <div class="font-light text-sm">логін</div>    
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.password">
                    </div>
                    <div class="font-light text-sm">пароль</div>    
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <select class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.amplua">
                            <option class="bg-[#02130E]" value="">Не задано</option>
                            <option class="bg-[#02130E]" value="ВР">ВР</option>
                            <option class="bg-[#02130E]" value="ЦЗ">ЦЗ</option>
                            <option class="bg-[#02130E]" value="ЛЗ">ЛЗ</option>
                            <option class="bg-[#02130E]" value="ПЗ">ПЗ</option>
                            <option class="bg-[#02130E]" value="ЦП">ЦП</option>
                            <option class="bg-[#02130E]" value="ПП">ПП</option>
                            <option class="bg-[#02130E]" value="ЛП">ЛП</option>
                            <option class="bg-[#02130E]" value="ЦФ">ЦФ</option>
                        </select>
                    </div>
                    <div class="font-light text-sm">амплуа</div>
                </div>
                <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                    <div class="font-bold text-lg w-[70%]">
                        <input type="text" class="bg-transparent text-[#DFF726] outline-none" v-model="store.currentStudent.player_number">
                    </div>
                    <div class="font-light text-sm">номер гравця</div>    
                </div>
            </div>
            <div class="border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-3   py-2 mx-4 mt-2">
                <div>
                    <div class="border-b-[0.2px] border-[#A5A3A3] border-opacity-25 grid grid-cols-12 w-full">
                        <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4">
                            <input type="number" class="bg-transparent w-full font-bold text-center text-[#DFF726] outline-none text-[18px]" v-model="store.currentStudent.height">
                            <br>
                            <span class="text-xs font-light">зріст, см</span>
                        </div>
                        <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4">
                            <input type="number" class="bg-transparent w-full font-bold text-center text-[#DFF726] outline-none text-[18px]" v-model="store.currentStudent.weight">
                            <br>
                            <span class="text-xs font-light">вага, кг</span>
                        </div>
                        <div class="text-[#FFF]  pl-3 py-2 text-center col-span-4">
                            <input type="number" class="bg-transparent w-full font-bold text-center text-[#DFF726] outline-none text-[18px]" v-model="store.currentStudent.foot_size">
                            <br>
                            <span class="text-xs font-light">розмір ноги</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-12">
                        <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4">
                            <select class="bg-transparent text-[#DFF726] text-center outline-none font-bold mt-[6px]" v-model="store.currentStudent.leading_foot">

                                <option class="bg-[#02130E]" value="">Не задано</option>
                                <option class="bg-[#02130E]" value="Ліва">Ліва</option>
                                <option class="bg-[#02130E]" value="Права">Права</option>

                            </select>
                            <br>
                            <span class="text-xs font-light">нога</span>
                        </div>
                        <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4 mt-[1px]">
                            <input type="number" class="bg-transparent w-full font-bold text-center text-[#DFF726] outline-none text-[18px]" v-model="store.currentStudent.breast_volume">
                            <br>
                            <span class="text-xs font-light">об'єм гр., см</span>
                        </div>
                        <div class="text-[#FFF]  pl-3 py-2 text-center col-span-4"><span class="font-bold text-lg">{{ store.currentStudent.start_training.split('T')[0].split('-').reverse().join('.') }}</span><br><span class="text-xs font-light">старт в школі</span></div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            :class="{'text-[#5AC47D]' : compareDateToNow(store.currentStudent.date_active_to), 'text-[#CA3F79]' : !compareDateToNow(store.currentStudent.date_active_to)}"
            class="text-center flex flex-col justify-between w-[100%] text-[20px] font-semibold mt-[30px] mb-[15px] mx-auto">  
            <div class="">{{ store.currentStudent.date_active_to.split('T')[0].split('-').reverse().join('.') }}</div>
            <div class="text-[12px]">дата закінчення тарифу</div>
        </div>

        <StudentTrainingCalendar 
            :planedTrainingDays="[]" 
            :attendedTrainings="attendedTrainings"
            :missedTrainings="missedTrainings"
        />

        <button @click="updateStudentButton" class="bg-[#5AC47D] rounded-[9px] text-[#02130E] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Оновити дані</div>
        </button>
        <button @click="" class="bg-[#CA3F79] rounded-[9px] text-[#02130E] font-semibold px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
            <div class="w-full">Виключити учня з групи</div>
        </button>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'
    import { updateStudent, getStudentAttendedTrainingsByStudentId } from '../services/apiRequests'
    import StudentTrainingCalendar from '../components/StudentTrainingCalendar/StudentTrainingCalendar.vue'

    export default {
        name: 'CurrentStudentView',

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const attendedTrainings = ref([])
            const missedTrainings = ref([])

            const compareDateToNow = (date) => {
                const now = new Date()
                const dateToCompare = new Date(date)
                return dateToCompare > now
            }

            onMounted(async () => {
                const studentTrainingsRes = await getStudentAttendedTrainingsByStudentId(store.currentStudent.student_id)
                console.log('studentTrainingsRes ------- ', studentTrainingsRes)
                attendedTrainings.value = studentTrainingsRes.data.trainings_attended
                missedTrainings.value = studentTrainingsRes.data.missed_trainings
            })

            const updateStudentButton = async () => {
                await updateStudent(store.currentStudent)
                console.log('send update student', store.currentStudent)
                router.push('/group-view')
            }

            return {
                store,
                updateStudentButton,
                attendedTrainings,
                missedTrainings,
                compareDateToNow
            }
        },

        components: {
            StudentTrainingCalendar
        }
    }
</script>

<style lang="scss" scoped>

</style>