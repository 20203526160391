<template>
    <svg :width="fullWidth + 45" :height="height">
        <rect :key="index" :x="0" :y="0" :width="percentWidth" :height="height" fill="#5AC47D" />
        <text :key="index" :x="percentWidth + 25" :y="height / 2 + 6" width="100px" text-anchor="middle" fill="#5AC47D">{{ percent.toFixed(1) }}%</text>

    </svg>
</template>

<script>
export default {
    props: {
        percent: {
            type: Number,
            required: true,
            default: 100
        },
        fullWidth: {
            type: Number,
            required: true,
            default: 200
        },
        height: {
            type: Number,
            default: 17,
        },
    },
    computed: {
        percentWidth() {
            return (this.fullWidth / 100) * this.percent;
        },
    },
};
</script>