<template>
    <div>
        <div @click="$router.push('/statistics')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div v-if="loading" class="bg-[#02130E] z-50 fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute left-[50%] top-[50%] ml-[-20px]"></div>
        </div>
        <div v-else>
            <div class="border-b-[1px] uppercase border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
                Нове повідомлення
            </div>
    
            <div class="flex justify-between w-[90%] mx-auto mt-[17px]">
                <div class="text-white text-[14px]">{{ currentDate }}</div>
                <div>
                    <div class="bg-[#5AC47D] mx-1 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                        <div class="block">
                            Активний
                        </div>
                    </div>  
                </div>
            </div>
    
            <div>
                <div class="w-[90%] mt-5 mx-auto relative flex bg-[#47484966] bg-opacity-5 rounded-[8px] border-[1px] border-[#8C8C8C33] mb-2">
                    <div class="h-[300px] rounded-l-[8px] bg-[#5AC47D] w-[7px] mr-[10px]"></div>
                    <div class="text-[12px] align-middle w-[93%] overflow-hidden h-[85%] flex items-center text-[#fff]">
                        <textarea v-model="message" class="w-full h-[300px] bg-transparent outline-none py-[10px]" placeholder="Текст повідомлення..."></textarea>
                    </div>
                </div>
            </div>
    
            <div class="flex justify-center items-center">
                <div class="w-full flex flex-col items-center relative">
                    <input
                        @change="changeGroupText($event.target.value)"
                        :value="groupText"
                        @focus="groupOptionsShow = true"
                        @blur="groupOptionsShow = false" 
                        class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] py-[7px] px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                        type="text"
                        placeholder="оберіть групу зі списку"
                    />
                    <div v-if="groupOptionsShow" class="absolute top-[36px] bg-[#474849] h-[200px] overflow-y-auto z-30 w-[85%]">
                        <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 py-2 bg-[#474849] text-white bg-opacity-40 cursor-pointer">{{groupOption.name}}</div>
                    </div>
                </div>
            </div>
    
            <button @click="sendMessage" class="bg-[#5AC47D] mb-[40px] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-8 mx-auto">
                <div class="w-full">Надіслати повідомлення</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { getAllGroups, broadcastMessageToGroup } from '../services/apiRequests'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'NewMessageView',
        setup() {
            const store = useMainStore()
            const router = useRouter()
            const currentDate = ref(new Date().toISOString().split('T')[0].split('-').reverse().join('.'))

            const group = ref(null)
            const groupText = ref('')
            
            const groupOptions = ref([])
            const groupOptionsShow = ref(false)

            const message = ref('')

            const loading = ref(false)

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const groupVisibleOptions = computed(() => {
                let result = []
                groupOptions.value.forEach(option => {
                    if (store.trainer_groups.find(trainerGroup => trainerGroup.name == option.name))
                        result.push(option)
                })
                return result
            })

            const sendMessage = async () => {
                loading.value = true
                const res = await broadcastMessageToGroup(group.value.group_id, message.value)
                loading.value = false
                router.push('/statistics')
            }

            onMounted(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data
            })

            return {
                currentDate,
                group,
                groupText,
                groupOptions,
                groupOptionsShow,
                setGroup,
                groupVisibleOptions,
                message,
                sendMessage,
                router,
                loading
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>