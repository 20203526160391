<template>
    <div class="overflow-x-hidden overflow-y-auto">
        <div @click="$router.push('/')" class="flex text-[#FFF] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] uppercase border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
            мої показники
        </div>

        <div class="flex justify-center mt-[37px]">
            <div class="flex bg-[#252526] rounded-[10px] justify-between items-center py-[7px] w-[241px] px-[21px] border-[#252526] border-[1px]">
                <div class="text-[#5AC47D] font-bold text-[20px]">₴</div>
                <div class="text-[#5AC47D] font-bold text-[20px]">{{ formatNumberWithThousandsSeparator(store.student_balance) }}</div>
                <div class="text-[#9FA2B4]">готівка</div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-[50px] border-y-[1px] pt-[15px] h-[145px] pb-[6px] border-[#8C8C8C] border-opacity-20">
            <div class="mb-[30px] flex justify-between">
                <div>
                    <select v-model="yearGroupIncomesYear" class="rounded-[4px] px-[5px] border-[1px] bg-transparent text-white text-[13px] border-white outline-none">
                        <option class="bg-[#02130E]" value="2023">2023</option>
                        <option class="bg-[#02130E]" value="2022">2022</option>
                        <option class="bg-[#02130E]" value="2021">2021</option>
                        <option class="bg-[#02130E]" value="2020">2020</option>
                        <option class="bg-[#02130E]" value="2019">2019</option>
                    </select>
                </div>

                <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ОПЛАТИ</span> <span class="text-[10px]">(всі групи)</span></div>
            </div>
            <div class="relative h-[55px]">
                <BarChart v-if="yearGroupIncomesFlag" class="absolute mt-[-20px] left-[50%] translate-x-[-50%]" :width="screenWidth + 10" :data="yearGroupIncomesChartData.data" :labels="yearGroupIncomesChartData.labels"/>
                <div v-else class="bg-[#02130E] w-full h-full ">
                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent mx-auto mb-[30px] mt-[50px]"></div>
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-[5px] border-b-[1px] pt-[15px] pb-[15px] border-[#8C8C8C] border-opacity-20">
            <div class="mb-[15px] flex justify-between">
                <div>
                    <select v-model="groupIncomesOption" class="rounded-[4px] px-[5px] border-[1px] bg-transparent text-white text-[13px] border-white outline-none">
                        <option class="bg-[#02130E]" value="1.2023">січень 2023</option>
                        <option class="bg-[#02130E]" value="2.2023">лютий 2023</option>
                        <option class="bg-[#02130E]" value="3.2023">березень 2023</option>
                        <option class="bg-[#02130E]" value="4.2023">квітень 2023</option>
                        <option class="bg-[#02130E]" value="5.2023">травень 2023</option>
                        <option class="bg-[#02130E]" value="6.2023">червень 2023</option>
                        <option class="bg-[#02130E]" value="7.2023">липень 2023</option>
                        <option class="bg-[#02130E]" value="8.2023">серпень 2023</option>
                        <option class="bg-[#02130E]" value="9.2023">вересень 2023</option>
                        <option class="bg-[#02130E]" value="10.2023">жовтень 2023</option>
                        <option class="bg-[#02130E]" value="11.2023">листопад 2023</option>
                        <option class="bg-[#02130E]" value="12.2023">грудень 2023</option>
                    </select>
                </div>  
                <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ОПЛАТИ</span> <span class="text-[10px]">(по кожній групі)</span></div>
            </div>
            <div v-if="groupIncomesFlag" class="overflow-y-auto h-[190px] px-[20px] relative">
                <div v-if="groupIncomesData.length > 0" v-for="income in groupIncomesData" class="flex items-center w-full py-[10px]">
                    <div class="relative bottom-[6px] w-[10%] text-[#FFF] text-[12px] mr-[20px]">{{ income.groupLabel }}</div>
                    <OneLine 
                        class="w-[90%]"
                        :fullWidth="screenWidth * 0.65"
                        :firstPart="income.cashPercentage"
                        :firstValue="formatNumber(income.cashIncomes)"
                        :secondPart="income.cashlessPercentage"
                        :secondValue="formatNumber(income.cashlessIncomes)"
                    />
                </div>
                <div v-else class="text-center text-[#FFF] relative top-[80px]">
                    Дані відсутні
                </div>
                <!-- <div class="bg-gradient-to-b from-transparent to-[#02130E] w-full h-[40%] absolute bottom-0 left-0"></div> -->
            </div>
            <div v-else class="bg-[#02130E] w-full h-[190px] relative">
                <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent mx-auto mb-[30px] relative top-[80px]"></div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-[5px] border-b-[1px] pt-[15px] pb-[15px] border-[#8C8C8C] border-opacity-20">
            <div class="mb-[30px] flex justify-between">
                    <div>
                        <select v-model="completedTrainingsYear" class="rounded-[4px] px-[5px] border-[1px] bg-transparent text-white text-[13px] border-white outline-none">
                            <option value="2023" class="bg-[#02130E]">2023</option>
                            <option value="2022" class="bg-[#02130E]">2022</option>
                            <option value="2021" class="bg-[#02130E]">2021</option>
                            <option value="2020" class="bg-[#02130E]">2020</option>
                            <option value="2019" class="bg-[#02130E]">2019</option>
                        </select>
                    </div>
                    <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ПРОВЕДЕНО ТРЕНУВАНЬ</span></div>
                </div>
            <div>
                <LineChart v-if="completedTrainingsFlag" :width="screenWidth * 0.90" :data="trainerAllCompletedTrainings.data" :labels="trainerAllCompletedTrainings.labels"/>
                <div v-else class="bg-[#02130E] w-full h-[100px] relative">
                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent mx-auto mb-[30px] relative top-[30px]"></div>
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mt-[5px] border-b-[1px] pt-[15px] pb-[15px] border-[#8C8C8C] border-opacity-20">
            <div class="mb-[30px] flex justify-between">
                <div>
                    <select v-model="groupAttendanceOption" class="rounded-[4px] px-[5px] border-[1px] bg-transparent text-white text-[13px] border-white outline-none">
                        <option class="bg-[#02130E]" value="1.2023">січень 2023</option>
                        <option class="bg-[#02130E]" value="2.2023">лютий 2023</option>
                        <option class="bg-[#02130E]" value="3.2023">березень 2023</option>
                        <option class="bg-[#02130E]" value="4.2023">квітень 2023</option>
                        <option class="bg-[#02130E]" value="5.2023">травень 2023</option>
                        <option class="bg-[#02130E]" value="6.2023">червень 2023</option>
                        <option class="bg-[#02130E]" value="7.2023">липень 2023</option>
                        <option class="bg-[#02130E]" value="8.2023">серпень 2023</option>
                        <option class="bg-[#02130E]" value="9.2023">вересень 2023</option>
                        <option class="bg-[#02130E]" value="10.2023">жовтень 2023</option>
                        <option class="bg-[#02130E]" value="11.2023">листопад 2023</option>
                        <option class="bg-[#02130E]" value="12.2023">грудень 2023</option>
                    </select>
                </div>
                <div class="text-white"><span class="text-white text-[15px] font-bold leading-tight">ВІДВІДУВАННЯ ТРЕНУВАНЬ</span></div>
            </div>
            <div v-if="groupsAttendanceFlag" class="overflow-y-auto h-[190px] px-[20px] relative">
                <div v-if="groupsAttendance.length > 0" v-for="attendance in groupsAttendance" class="flex items-center w-full pl-[0px] py-[10px]">
                    <div class="text-[#FFF] w-[10%] mr-[20px] text-[12px]">{{ attendance.group_name }}</div>
                    <PercentLine class="w-[90%]" :percent="attendance.attendance" :fullWidth="screenWidth * 0.87"/>
                </div>
                <div v-else class="text-white w-full text-center relative top-[90px]">
                    Дані відсутні
                </div>
                <!-- <div class="bg-gradient-to-b from-transparent to-[#02130E] w-full h-[40%] absolute bottom-0 left-0"></div> -->
            </div>
            <div v-else class="bg-[#02130E] w-full h-[190px] relative">
                <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent mx-auto mb-[30px] relative top-[80px]"></div>
            </div>
        </div>

        <button @click="$router.push('/new-message')" class="bg-[#5AC47D] mb-[40px] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-8 mx-auto">
            <div class="w-full">Створити повідомлення</div>
        </button>
    </div>
</template>

<script>
    import { useMainStore } from '../stores/mainStore'
    import { ref, computed, onMounted, watch } from 'vue'
    import { getAllIncomesByGroupInYear, getTrainerCompletedTrainingsByYear, getGroupsAttendanceByYearAndMonth } from '../services/apiRequests'

    import BarChart from '../components/StatisticsBlock/BarChart.vue'
    import OneLine from '../components/StatisticsBlock/OneLine.vue'
    import LineChart from '../components/StatisticsBlock/LineChart.vue'
    import PercentLine from '../components/StatisticsBlock/PercentLine.vue'

    export default {
        name: 'StatisticsView',

        setup() {
            const store = useMainStore()
            const screenWidth = ref(window.innerWidth > 700 ? 700 : window.innerWidth)
            const yearGroupIncomesYear = ref(new Date().getFullYear())
            const yearGroupIncomesFlag = ref(false)
            const yearGroupIncomesChartData = ref(
                {
                    data: [1, 0, 3, 4, 4000, 6, 7, 2000, 3200, 1100, 2100, 3900],
                    labels: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
                }
            )
            const yearIncomesObject = ref({})

            const groupIncomesMonth = ref(new Date().getMonth() + 1)
            const groupIncomesYear = ref(new Date().getFullYear())
            const groupIncomesOption = ref(`${groupIncomesMonth.value}.${groupIncomesYear.value}`)
            const groupIncomesFlag = ref(false)
            const groupIncomesData = ref([]) 


            const completedTrainingsFlag = ref(false)
            const trainerAllCompletedTrainings = ref({
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    labels: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
                })

            const groupsAttendance = ref([])
            const groupsAttendanceFlag = ref(false)

            const formatNumber = (number) => {
                const abbreviations = ['K', 'M', 'B', 'T'];
                const powers = [3, 6, 9, 12];

                for (let i = abbreviations.length - 1; i >= 0; i--) {
                    const size = Math.pow(10, powers[i]);
                    if (number >= size) {
                    const roundedNumber = (number / size).toFixed(1);
                    return roundedNumber + abbreviations[i];
                    }
                }

                return number.toString();
            }

            const formatNumberWithThousandsSeparator = (number) => {
                const formattedNumber = Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ');
                return formattedNumber;
            }

            watch(() => window.innerWidth, (newValue, oldValue) => {
                screenWidth.value = newValue
            })

            const updateGroupIncomesData = () => {
                groupIncomesFlag.value = false
                const currentMonthGroupIncomes = yearIncomesObject.value.filter(income => new Date(income.date).getMonth() + 1 == groupIncomesMonth.value && new Date(income.date).getFullYear() == groupIncomesYear.value)
                if (currentMonthGroupIncomes.length == 0) {
                    groupIncomesData.value = []
                    groupIncomesFlag.value = true
                    return
                }
                const currentMonthGroupIncomesSum = currentMonthGroupIncomes[0].groups.reduce((acc, group) => acc + group.all_incomes, 0)
                groupIncomesData.value = [] 
                
                currentMonthGroupIncomes[0].groups.forEach(group => {
                    if (store.trainer_groups.find(trainerGroup => trainerGroup.name == group.group_name)) {
                        groupIncomesData.value.push(
                            {
                                groupLabel: group.group_name,
                                allIncomes: group.all_incomes,
                                cashIncomes: group.cash_incomes,
                                cashlessIncomes: group.cashless_incomes,
                                cashPercentage: Math.round(group.cash_incomes / group.all_incomes * 100),
                                cashlessPercentage: Math.round(group.cashless_incomes / group.all_incomes * 100)
                            }
                        )
                    }
                })
                groupIncomesFlag.value = true
            }

            const completedTrainingsYear = ref(new Date().getFullYear())

            const updateCompletedTrainings = async (year, trainer_id) => {
                completedTrainingsFlag.value = false
                const trainings = await getTrainerCompletedTrainingsByYear(year, trainer_id)
                trainerAllCompletedTrainings.value.data = trainings.data
                completedTrainingsFlag.value = true
            }

            const updateGroupsAttendance = async (year, month) => {
                groupsAttendanceFlag.value = false
                const resp = await getGroupsAttendanceByYearAndMonth(year, month)
                console.log('groupsAttendance ----- ', resp.data)
                groupsAttendance.value = resp.data.filter(group => store.trainer_groups.find(trainerGroup => trainerGroup.name == group.group_name))
                groupsAttendanceFlag.value = true
            }

            const updateIncomesByGroupInYear = async (year) => {
                const incomes = await getAllIncomesByGroupInYear(year)
                
                if (incomes.data.length > 0) {
                    console.log('incomes ------ ', incomes)
                    yearIncomesObject.value = incomes.data
                    for (let i = 0; i < 12; i++) {
                        const currentMonthGroupIncomes = incomes.data.filter(income => new Date(income.date).getMonth() + 1 == i + 1)
                        if (currentMonthGroupIncomes.length == 0) {
                            yearGroupIncomesChartData.value.data[i] = 0
                            continue
                        }
                        const currentMonthGroupIncomesSum = currentMonthGroupIncomes[0].groups.reduce((acc, group) => {
                            if (store.trainer_groups.find(trainerGroup => trainerGroup.name == group.group_name)) {
                                return acc + group.all_incomes
                            } else {
                                return acc
                            }
                        }, 0)
                        yearGroupIncomesChartData.value.data[i] = currentMonthGroupIncomesSum
                    }
                    yearGroupIncomesFlag.value = true
                } else {
                    yearIncomesObject.value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    yearGroupIncomesChartData.value.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    yearGroupIncomesFlag.value = true
                }
            }

            watch(yearGroupIncomesYear, async (newValue, oldValue) => {
                await updateIncomesByGroupInYear(newValue)
            })

            watch(groupIncomesOption, (newValue, oldValue) => {
                if (newValue.length > 0) {
                    groupIncomesMonth.value = Number(newValue.split('.')[0])
                    groupIncomesYear.value = Number(newValue.split('.')[1])
                    updateGroupIncomesData()
                }
            })

            watch(completedTrainingsYear, async (newValue, oldValue) => {
                await updateCompletedTrainings(newValue, store.trainer.trainer_id)
            })

            const groupAttendanceMonth = ref(new Date().getMonth() + 1)
            const groupAttendanceYear = ref(new Date().getFullYear())
            const groupAttendanceOption = ref(`${groupAttendanceMonth.value}.${groupAttendanceYear.value}`)

            watch(groupAttendanceOption, async (newValue, oldValue) => {
                if (newValue.length > 0) {
                    groupAttendanceMonth.value = Number(newValue.split('.')[0])
                    groupAttendanceYear.value = Number(newValue.split('.')[1])
                    await updateGroupsAttendance(groupAttendanceYear.value, groupAttendanceMonth.value)
                }
            })

            onMounted(async () => {
                await updateIncomesByGroupInYear(yearGroupIncomesYear.value)
                updateGroupIncomesData()
                await updateCompletedTrainings(new Date().getFullYear(), store.trainer.trainer_id)
                await updateGroupsAttendance(new Date().getFullYear(), new Date().getMonth() + 1)
            })

            return {
                store,
                formatNumberWithThousandsSeparator,
                formatNumber,
                yearGroupIncomesFlag,
                yearGroupIncomesChartData,
                groupIncomesData,
                updateGroupIncomesData,
                trainerAllCompletedTrainings,
                groupsAttendance,
                yearGroupIncomesYear,
                groupIncomesOption,
                completedTrainingsYear,
                groupAttendanceMonth,
                groupAttendanceYear,
                groupAttendanceOption,
                screenWidth,
                groupIncomesFlag,
                completedTrainingsFlag,
                groupsAttendanceFlag
            }
        },

        components: {
            BarChart,
            OneLine,
            LineChart,
            PercentLine
        }
    }
</script>

<style lang="scss" scoped>

</style>