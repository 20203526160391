<template>
    <svg :width="fullWidth" :height="height">
        <rect :key="index" :x="0" :y="0" :width="firstPartWidth" :height="height - 15" fill="#5AC47D" />
        <rect :key="index" :x="firstPartWidth" :y="0" :width="secondPartWidth" :height="height - 15" fill="#A1D9F7" />
        <text :key="index" :x="borderGap(firstPart)" :y="height" text-anchor="middle" fill="#5AC47D">{{ firstPart }}</text>
        <text :key="index" :x="fullWidth - borderGap(secondPart)" :y="height" text-anchor="middle" fill="#A1D9F7">{{ secondPart }}</text>

    </svg>
</template>

<script>
export default {
    props: {
        firstPart: {
            type: Number,
            required: true,
            default: 50
        },
        secondPart: {
            type: Number,
            required: true,
            default: 50
        },
        fullWidth: {
            type: Number,
            required: true,
            default: 300
        },
        height: {
            type: Number,
            default: 37,
        },
    },
    computed: {
        firstPartWidth() {
            return (this.fullWidth / 100) * this.firstPart;
        },
        secondPartWidth() {
            return (this.fullWidth / 100) * this.secondPart;
        },
        borderGap() {
            return value => `${value}`.length * 5;
        },
    },
};
</script>