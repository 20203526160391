<template>
    <div class="bg-[#02130E] fixed h-full w-full mt-[-10px]">
        <div @click="$emit('close')" class="flex text-[#FFF] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-white">
                Назад
            </div>
        </div>
        <div v-if="loadingSpinnerFlag" class="bg-[#02130E] z-50 fixed w-full h-full">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#CA3F79] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
        </div>
        <div v-else>
            <div class="border-b-[1px] uppercase border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
                Створити подію
            </div>
            
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#FFF] text-[20px] font-medium">
                        Тип тренування
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <select v-model="trainingType" class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] py-[9px] px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" >
                                <option value="none" class="bg-[#8C8C8C] bg-opacity-10 text-[#8C8C8C]" disabled>оберіть вид події зі списку</option>
                                <option value="training" class="bg-[#8C8C8C] bg-opacity-10 text-[#000]">Тренування</option>
                                <option value="gym" class="bg-[#8C8C8C] bg-opacity-10 text-[#000]">Спортзал</option>
                                <option value="competition" class="bg-[#8C8C8C] bg-opacity-10 text-[#000]">Змагання</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#FFF] text-[20px] font-medium">
                        Дата
                    </div> -->
                    <div class="flex justify-center items-center">
                        <input v-model="trainingDate" :disabled="addCurrentDateFlag" class="custom-date-input bg-[#474849] disabled:opacity-60 bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] py-[6px] px-2 w-[90%] " type="date">
                    </div>
                </div>
            </div>
            <div class="mt-[32px]">
                <!-- <div class="text-center mb-2 text-[#FFF] text-[20px] font-medium">
                    Час тренування
                </div> -->
                <div class="text-center mb-2 text-[#FFF] text-[12px] font-light">
                    оберіть час тренування (початок - кінець)
                </div>
                <div class="flex justify-center items-center">
                    <input v-model="trainingTime.start" class="custom-time-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] px-1 py-[6px]" type="time">
                    <span class="font-bold mx-2 text-[#FFF] text-[20px]">></span>
                    <input v-model="trainingTime.end" class="custom-time-input bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C] outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] px-1 py-[6px]" type="time">
                </div>
            </div>
    
            <MultiSelect 
                @update="trainingRepeat = $event"
                @enable="addCurrentDateFlag = false"
                @disable="addCurrentDateFlag = true"
            />
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Група
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <input
                                @change="changeGroupText($event.target.value)"
                                :value="groupText"
                                @focus="groupOptionsShow = true"
                                @blur="groupOptionsShow = false" 
                                class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] py-[7px] px-2 w-[90%] bg-opacity-20 border-[1px]" 
                                :class="{
                                    'bg-[#474849] border-[#8C8C8C]' : validationObject.group, 
                                    'bg-[#DE3A73] border-[#DE3A73]' : !validationObject.group
                                }"
                                type="text"
                                placeholder="оберіть групу зі списку"
                            />
                            <div v-if="groupOptionsShow" class="absolute top-[36px] bg-[#8C8C8C] h-[200px] overflow-y-auto z-30 w-[85%]">
                                <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 py-2 bg-[#8C8C8C] bg-opacity-40 cursor-pointer">{{groupOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Локація
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <input
                                @change="changeLocationText($event.target.value)"
                                :value="locationText"
                                @focus="locationOptionsShow = true"
                                @blur="locationOptionsShow = false" 
                                class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] py-[7px] px-2 w-[90%] bg-opacity-20 border-[1px]" 
                                type="text"
                                :class="{
                                    'bg-[#474849] border-[#8C8C8C]' : validationObject.location, 
                                    'bg-[#DE3A73] border-[#DE3A73]' : !validationObject.location
                                }"
                                placeholder="оберіть локацію зі списку"
                            />
                            <div v-if="locationOptionsShow" class="absolute top-[36px] bg-[#8C8C8C] z-30 w-[85%]">
                                <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="truncate w-full px-1 py-2 bg-[#8C8C8C] bg-opacity-40 cursor-pointer">{{locationOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Коментар
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <textarea
                                v-model="description"
                                class="outline-none text-[#8C8C8C] placeholder-gray-500 rounded-[8px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]" 
                                type="text"
                                rows="4"
                                placeholder="коментар до події"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-[32px]">
                <div>
                    <!-- <div class="text-center mb-2 text-[#A1D9F7] text-[20px] font-medium">
                        Тип тренування
                    </div> -->
                    <div class="flex justify-center items-center">
                        <div class="w-full flex justify-center relative">
                            <select :value="trainerId" @change="changeTrainer" class="outline-none text-[#FFF] placeholder-gray-500 rounded-[9px] py-1.5 px-2 w-[90%] bg-[#474849] bg-opacity-20 border-[1px] border-[#8C8C8C]">
                                <option value="" class="bg-[#252526]">Тренер не заданий</option>
                                <option v-for="trainer in allTrainers" :value="trainer.trainer_id" class="bg-[#252526]">{{ trainer.name + ' ' + trainer.surname }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-[32px] flex px-[25px] items-center justify-between">
                <div class="flex">
                    <div v-if="additionalFiles.length == 0 && newAdditionalFiles.length == 0" class="text-white opacity-25">Файли незакріплені</div>
                    <div v-for="file in additionalFiles" @click="showImageViewer = true; currentImage = file" class="cursor-pointer mr-[5px]">
                        <img src="@/mobileApp/assets/picture_icon.svg" alt="">
                    </div>
                    <div v-for="file in newAdditionalFiles" @click="showNewImageViewer = true; newCurrentImage = file" class="cursor-pointer mr-[5px]">
                        <img src="@/mobileApp/assets/picture_icon.svg" alt="">
                    </div>
                </div>
                <div>
                    <img src="@/mobileApp/assets/upload_icon_light.svg" class="cursor-pointer" @click="uploadAdditionalPhoto">
                    <input 
                        ref="fileInput"
                        type="file" 
                        class="hidden"
                        @change="newAdditionalFiles.push($event.target.files[0])"
                    />
                </div>
            </div>

            <div v-if="errorAlertShow" class="w-full fixed bottom-0 h-[150px] bg-[#CA3F79] rounded-t-[10px] flex flex-col justify-center items-center">
                <div class="text-white">Таке тренування вже існує в системі</div>
                <div @click="errorAlertShow = false" class="mt-[20px]">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L1 13" stroke="#02130E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 1L13 13" stroke="#02130E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
    
            <button @click="sendData" class="bg-[#5AC47D] rounded-[9px] text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-5 mx-auto">
                <div class="w-full">Додати тренування</div>
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, addNewTraining, getAllTrainers } from '../../services/apiRequests'
    import { useMainStore } from '../../stores/mainStore'
    import MultiSelect from './MultiSelect.vue'

    async function compressImageToBlobAsync(file, maxWidth, maxHeight, quality) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function () {
            const img = new Image();
            img.src = reader.result;
            img.onload = function () {
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                }

                if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                // Получаем Blob сжатого изображения
                canvas.toBlob(
                function (blob) {
                    if (blob) {
                    // Создаем новый объект File на основе Blob
                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                    resolve(compressedFile);
                    } else {
                    reject(new Error('Ошибка при создании Blob'));
                    }
                },
                'image/jpeg',
                quality / 100
                );
            };

            img.onerror = function () {
                reject(new Error('Ошибка при загрузке изображения'));
            };
            };

            reader.onerror = function () {
            reject(new Error('Ошибка при чтении файла'));
            };

            reader.readAsDataURL(file);
        });
    }

    export default {
        name: 'NewTrainingForm',

        props: {
            selectedDate: {
                type: Date,
                required: true
            }
        },

        setup(props, { emit }) {
            const store = useMainStore()
            const group = ref(null)
            const groupText = ref('')
            
            const location = ref(null)
            const locationText = ref('')

            const groupOptions = ref([])
            const locationOptions = ref([])

            const trainingType = ref('none')
            const description = ref('')

            const trainingDate = ref('')

            const trainingRepeat = ref([])
            const addCurrentDateFlag = ref(false)
            const loadingSpinnerFlag = ref(false)

            const errorAlertShow = ref(false)

            const additionalFiles = ref([])

            const fileInput = ref(null)
            const newAdditionalFiles = ref([])

            const currentImage = ref(null)
            const newCurrentImage = ref(null)

            const showNewImageViewer = ref(false)

            const deleteNewPhoto = (photo) => {
                newAdditionalFiles.value = newAdditionalFiles.value.filter(file => file.name !== photo.name)
            }

            const uploadAdditionalPhoto = () => {
                fileInput.value.click()
            }

            const validationObject = ref({
                location: true,
                group: true
            })

            const settedDate = new Date(props.selectedDate)
            const settedYear = settedDate.getFullYear() >= 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 >= 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() >= 10 ? settedDate.getDate() : `0${settedDate.getDate()}`

            const allTrainers = ref([])
            const trainerId = ref('')
            const trainerLabel = ref('')

            const showImageViewer = ref(false)

            const changeTrainer = (e) => {
                trainerId.value = e.target.value
                trainerLabel.value = e.target.options[e.target.selectedIndex].text
            }

            onBeforeMount(async () => {
                const date = new Date(props.selectedDate)
                const day = date.getDate() > 9? date.getDate() : '0' + date.getDate()
                const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
                const year = date.getFullYear()

                trainingDate.value = `${year}-${month}-${day}`

                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const trainersRes = await getAllTrainers()
                allTrainers.value = trainersRes.data
            })

            const formattedDate = computed(() => {
                const date = new Date(props.selectedDate)
                const day = date.getDate() > 9? date.getDate() : '0' + date.getDate()
                const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
                const year = date.getFullYear()

                return `${year}-${month}-${day}`
            })

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                location.value = locationOption
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                trainerId.value = groupOption.trainer_id
                trainerLabel.value = allTrainers.value.find(trainer => trainer.trainer_id == groupOption.trainer_id).name + ' ' + allTrainers.value.find(trainer => trainer.trainer_id == groupOption.trainer_id).surname
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: '00:00',
                end: '00:00'
            })

            // const sendData = async () => {
            //     trainingType.value = trainingType.value == 'none' ? null : trainingType.value
            //     addNewTraining(
            //         group.value.name, 
            //         group.value.group_id, 
            //         location.value.small_name, 
            //         location.value.location_id, 
            //         trainingTime.value.start, 
            //         trainingTime.value.end, 
            //         new Date(trainingDate.value).toISOString().split('T')[0].split('-').reverse().join('-'),
            //         trainingType.value,
            //         description.value
            //     ).then(res => {
            //         console.log('res.datasdasdsada ---------- ', res.data)
            //         emit('updateTrainings')
            //         emit('close')
            //     })
            // }

            const sendData = async () => {
                let newAdditionalFilesCompressed = []

                if (newAdditionalFiles.value.length > 0) {
                    for (let i = 0; i < newAdditionalFiles.value.length; i++) {
                        const compressedFile = await compressImageToBlobAsync(newAdditionalFiles.value[i], 600, 600, 100)
                        newAdditionalFilesCompressed.push(compressedFile)
                    }
                }

                try {
                    if (group.value && location.value) {
                        trainingType.value = trainingType.value == 'none' ? null : trainingType.value
                        if (!addCurrentDateFlag.value) {
                            loadingSpinnerFlag.value = true
                            const newTrainingRes = await addNewTraining(
                                group.value.name, 
                                group.value.group_id, 
                                location.value.small_name, 
                                location.value.location_id, 
                                trainingTime.value.start, 
                                trainingTime.value.end, 
                                trainingDate.value,
                                trainingType.value,
                                description.value,
                                trainerId.value,
                                trainerLabel.value,
                                newAdditionalFilesCompressed,
                            )
        
                            if (newTrainingRes.data.status == 'success') {
                                // console.log('result adding', res)
                                loadingSpinnerFlag.value = false
                                emit('updateTrainings')
                                emit('close')
                            } else {
                                loadingSpinnerFlag.value = false
                                errorAlertShow.value = true
                            }
                            
                        } else {
                            loadingSpinnerFlag.value = true
                            const days = []
                            trainingRepeat.value.forEach(day => {
                                if(day.selected) {
                                    const allCurrentDayInMonth = getDatesByDayOfWeek(settedMonth, settedYear, day.value)
                                    days.push(...allCurrentDayInMonth)
                                }
                            })
                            for (let i = 0; i < days.length; i++) {
                                console.log('days[i] ------- ', days[i])
                                const correctDate = new Date(new Date(days[i]).setDate(new Date(days[i]).getDate() + 1, new Date(days[i]).getMonth(), new Date(days[i]).getFullYear())).toISOString()
                                await addNewTraining(
                                    group.value.name, 
                                    group.value.group_id, 
                                    location.value.small_name, 
                                    location.value.location_id, 
                                    trainingTime.value.start, 
                                    trainingTime.value.end, 
                                    correctDate,
                                    trainingType.value,
                                    description.value,
                                    trainerId.value,
                                    trainerLabel.value,
                                    newAdditionalFilesCompressed,
                                )
                            }
                            loadingSpinnerFlag.value = false
                            emit('updateTrainings')
                            emit('close')
                        }
                    } else {
                        validationObject.value = {
                            location: !!location.value,
                            group: !!group.value
                        }
                    }
                } catch (error) {
                    console.log('error', error)
                    loadingSpinnerFlag.value = false
                    errorAlertShow.value = true
                }

            }

            const getDatesByDayOfWeek = (month, year, dayOfWeek) => {
                const dates = [];
                const date = new Date(year, month - 1, 1);
                const daysInMonth = new Date(year, month, 0).getDate();
                
                let firstDayOfWeek = date.getDay();
                if (firstDayOfWeek > dayOfWeek) {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek + 7;
                } else {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek;
                }
                
                date.setDate(firstDayOfWeek + 1);
                dates.push(new Date(date));
                
                while (date.getDate() + 7 <= daysInMonth) {
                    date.setDate(date.getDate() + 7);
                    dates.push(new Date(date));
                }
                
                console.log('dates', dates)
                return dates;
            }

            return { 
                group, 
                groupText, 
                groupOptions, 
                groupVisibleOptions, 
                changeGroupText, 
                groupOptionsShow,
                setGroup,
                location,
                locationText,
                locationOptions,
                locationVisibleOptions,
                changeLocationText,
                locationOptionsShow,
                setLocation,
                trainingTime,
                sendData,
                formattedDate,
                trainingType,
                description,
                trainingDate,
                trainingRepeat,
                addCurrentDateFlag,
                loadingSpinnerFlag,
                errorAlertShow,
                validationObject,
                allTrainers,
                trainerId,
                trainerLabel,
                changeTrainer,
                uploadAdditionalPhoto,
                fileInput,
                additionalFiles,
                newAdditionalFiles,
                currentImage,
                newCurrentImage,
                deleteNewPhoto,
                showImageViewer,
                showNewImageViewer
            }
        },

        components: {
            MultiSelect
        }
    }
</script>

<style lang="scss" scoped> 
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: url('../../assets/white_calendar.svg');
    cursor: pointer;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    background-image: url('../../assets/clock_icon.svg');
    cursor: pointer;
}
</style>