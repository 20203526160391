<template>
    <div class="bg-[#02130E] w-full h-full pt-2 pb-5 rounded-lg px-2 flex justify-between flex-col">
        <div class="flex justify-end">
            <img @click="$emit('close')" src="@/mobileApp/assets/cross_icon.svg" class="w-4 cursor-pointer z-50 fill-[#CA3F79]">
        </div>
        <div class="flex justify-center overflow-auto items-center h-[90%] bg-slate-400 mx-auto rounded-[10px] bg-opacity-30 w-[90%] mt-[-19px]">
            <iframe name = "myframe" ref="fileIframe" width = "550px" height = "550px" :src="apiURL + 'static/' + fileUrl" ></iframe>
        </div>
        <div class="flex justify-center mt-2">
            <button @click="$emit('delete', fileUrl)" class="bg-[#CA3F79] mr-2 hover:bg-[#bf2c5f] text-white px-2 py-1 rounded-[10px]">Видалити</button>
            <!-- <button @click="downloadFile" class="bg-[#CA3F79] hover:bg-[#bf2c5f] text-white px-2 py-1 rounded-[10px]">Завантажити</button> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'file-view',

        props: {
            fileUrl: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const apiURL = process.env.VUE_APP_API_URL
            
            return {
                apiURL
            }
        },

        methods: {
            downloadFile() {
                const iframe = this.$refs.fileIframe;
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                const fileUrl = iframeDocument.URL; // Получаем URL из iframe

                // Создаем скрытый элемент <a> для скачивания файла
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'downloaded_file.pdf'; // Установите имя файла для скачивания
                link.style.display = 'none';

                // Добавляем элемент в DOM и запускаем скачивание
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>